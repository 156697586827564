<template>
  <div class="zupu-ancestor-name-page">
    <header class="text_only">
      <p class="title">Zupu Translation Services</p>
      <h1>Locate an Ancestor's Name in a Zupu</h1>
      <div class="header_actions">
        <mcr-buttons-row>
          <mcr-button-router-link-to-pr label="Enquire"></mcr-button-router-link-to-pr>
        </mcr-buttons-row>
      </div>
    </header>
    <div class="readable_content">
      <img
        :data-src="$getAsset('/assets/services/translation/researcher-pointing-name-zupu.jpg')"
        class="lazyload with-shadow"
        width="800"
        height="600"
      />
      <p>Are you looking for a specific ancestor in your family tree book, but struggling to read Chinese?</p>
      <p>Our translators are here to help!</p>
      <h4>How it Works</h4>
      <div class="figures-highlight">
        <div class="breakdown-property">
          <span class="price_label">Average Price Per Page</span>
          <span class="price">US$ 0.55</span>
        </div>
        <div class="breakdown-property">
          <span class="price_label">+ Fee if name provided is not Chinese</span>
          <span class="price">US$ 20</span>
        </div>
        <div class="breakdown-property">
          <span class="price_label">Report received</span>
          <span class="price"> >2 weeks</span>
        </div>
      </div>
      <div class="process-walkthrough">
        <div class="label">Step-by-step</div>
        <ul>
          <li>
            First, we'll ask you for information about the ancestor you wish to find. If you cannot provide their name
            in Chinese, we can also search using their English name (for an extra $20).
          </li>
          <li>Our translators will go through the whole zupu to match a name with your ancestor.</li>
          <li>
            If successful, we’ll send you a report with the page where your ancestor is mentioned, along with a
            summarized biography, if available.
          </li>
          <li>
            If we find more than one match, we will share with you the list of pages and who we think is most likely
            your ancestor.
          </li>
        </ul>
      </div>
      <h4>Sample Deliverable</h4>
      <div class="example-report">
        <img
          :data-src="$getAsset('/assets/services/translation/zupu-highlighted-page-deliverable.png')"
          width="800"
          height="260"
          class="lazyload with-shadow"
        />
      </div>
      <div class="faq">
        <div class="bold">Frequently Asked Questions</div>
        <ul class="desc">
          <li>
            <mcr-wiki-glossary-link
              naturalId="whatifnonamematch"
              text="Will I still be charged if you don’t find a matching name in the zupu?"
            />
          </li>
        </ul>
      </div>

      <h5 class="centered">Unlock your family history today.</h5>
      <mcr-buttons-row>
        <mcr-button-router-link-to-pr label="Get in touch"></mcr-button-router-link-to-pr>
      </mcr-buttons-row>
    </div>
  </div>
</template>

<script>
import McrButtonRouterLinkToPr from '@common/elements/buttons/mcrButtonRouterLinkToPr';
import mcrButtonsRow from '@common/elements/buttons/mcrButtonsRow';

const mcrWikiGlossaryLink = () => import('@common/elements/glossary/mcrWikiGlossaryLink');

export default {
  metaInfo: {
    title: "Locate an Ancestor's Name in a Zupu",
    meta: [
      {
        vmid: 'description',
        name: 'description',
        content: "Get help finding an ancestor's name in your Chinese family tree book.",
      },
    ],
  },
  components: {
    McrButtonRouterLinkToPr,
    mcrButtonsRow,
    mcrWikiGlossaryLink,
  },
};
</script>

<style lang="scss" scoped>
@import '~@/components/common/styles/services.scss';
</style>
