<template>
  <div class="zupu-ancestral-line-page">
    <header class="text_only">
      <p class="title">Zupu Translation Services</p>
      <h1>Translate your Straight Ancestral Line</h1>
      <div class="header_actions">
        <mcr-buttons-row>
          <mcr-button-router-link-to-pr label="Enquire"></mcr-button-router-link-to-pr>
        </mcr-buttons-row>
      </div>
    </header>
    <div class="readable_content">
      <img
        :data-src="$getAsset('/assets/services/tree/lineage-noshadow.jpg')"
        class="lazyload"
        width="800"
        height="650"
      />
      <p>
        <span>Do you have a Chinese family tree book (zupu or jiapu)?</span><br />
        <span>Do you want to know how far back your ancestry goes?</span>
      </p>
      <p>We’ll comb through your zupu to find all of your direct ancestors, and preserve them in a straight line</p>

      <h4>How it Works</h4>
      <div class="figures-highlight">
        <div class="breakdown-property">
          <span class="price_label">Average Price Per Generation</span>
          <span class="price">US$25</span>
        </div>
        <div class="breakdown-property">
          <span class="price_label">Report received</span>
          <span class="price"> >1.5 months </span>
        </div>
      </div>
      <div class="process-walkthrough">
        <div class="label">Step-by-step</div>
        <ul>
          <li>
            First, we'll ask you for information about the most recent ancestor mentioned in your zupu. If you don’t
            know where he/she is mentioned, we’ll first need to locate their name in the zupu.
          </li>
          <li>We’ll ask you how much detail you want translated (see below).</li>
          <li>
            Our translators will locate each of your direct ancestors, and will transcribe their information into a
            password-protected lineage.
          </li>
          <li>
            Upon completion, we’ll share the line with you and answer any questions you may have about our translation.
          </li>
        </ul>
      </div>
      <img
        :data-src="$getAsset('/assets/services/translation/huang-juzheng-sample-bio.png')"
        class="lazyload with-shadow"
        width="800"
        height="600"
      />
      <p class="supplemental">What's included</p>
      <p>
        Where available, each ancestral line can be customized to include the following information about ancestors:
      </p>
      <ul>
        <li>Names in Chinese characters</li>
        <li>Names in Pinyin</li>
        <li>Generation number</li>
        <li>Dates of birth, death, marriage</li>
        <li>Names of wives</li>
        <li>Burial information</li>
        <li>Names and number of siblings</li>
      </ul>
      <p>For an additional fee, we also provide:</p>
      <ul>
        <li>Names in Cantonese, Hokkien, Toishanese or other dialect pronunciations</li>
        <li>In-depth biographies</li>
      </ul>
      <div class="faq">
        <div class="bold">Frequently Asked Questions</div>
        <ul class="desc">
          <li>
            <mcr-wiki-glossary-link naturalId="translationfees" text="How are translation fees calculated?" />
          </li>
        </ul>
      </div>
      <h5 class="centered">Discover your family tree today.</h5>
      <mcr-buttons-row>
        <mcr-button-router-link-to-pr label="Get in touch"></mcr-button-router-link-to-pr>
      </mcr-buttons-row>
    </div>
  </div>
</template>

<script>
import McrButtonRouterLinkToPr from '@common/elements/buttons/mcrButtonRouterLinkToPr';
import mcrButtonsRow from '@common/elements/buttons/mcrButtonsRow';

const mcrWikiGlossaryLink = () => import('@common/elements/glossary/mcrWikiGlossaryLink');

export default {
  metaInfo: {
    title: 'Translate Straight Ancestral Line',
    meta: [
      {
        vmid: 'description',
        name: 'description',
        content:
          'Work with our specialist translators to convert your zupu, jiapu or family tree book into a straight ancestor line, from you all the way to your earliest known ancestor.',
      },
    ],
  },
  components: {
    McrButtonRouterLinkToPr,
    mcrButtonsRow,
    mcrWikiGlossaryLink,
  },
};
</script>

<style lang="scss" scoped>
@import '~@/components/common/styles/services.scss';
</style>
