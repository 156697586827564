<template>
  <div class="zupu-generation-poem-page">
    <header class="text_only">
      <p class="title">Zupu Translation Services</p>
      <h1>Translate your Family's Poem</h1>
      <div class="header_actions">
        <mcr-buttons-row>
          <mcr-button-router-link-to-pr label="Enquire"></mcr-button-router-link-to-pr>
        </mcr-buttons-row>
      </div>
    </header>
    <div class="readable_content">
      <img
        :data-src="$getAsset('/assets/services/translation/grandpa-baby.jpg')"
        class="lazyload with-shadow"
        width="800"
        height="530"
      />
      <p>
        <span>Do you have a Chinese family tree book (zupu or jiapu)?</span><br />
        <span>Are you looking to name your children / grandchildren according to family tradition?</span><br />
        <span>Do you want to know the significance behind your family’s names, but can’t read Chinese?</span><br />
      </p>

      <p>Our translators are here to help!</p>

      <h4>How it Works</h4>
      <div class="figures-highlight">
        <div class="breakdown-property">
          <span class="price_label">Average Price</span>
          <span class="price">US$100</span>
        </div>
        <div class="breakdown-property">
          <span class="price_label">Report received</span>
          <span class="price">>2 weeks</span>
        </div>
      </div>
      <div class="process-walkthrough">
        <div class="label">Step-by-step</div>
        <ul>
          <li>First, we'll ask you to share your zupu with us and tell us your family’s generation names.</li>
          <li>We’ll check the zupu to find the poem.</li>
          <li>
            We’ll transcribe the poem for you into a simple table, including pronunciation and meaning of each
            character.
          </li>
          <li>
            (Optional add-on) If you need assistance choosing a name in Chinese, we’ll provide some suggestions tailored
            to your needs.
          </li>
        </ul>
      </div>
      <h4>Sample Deliverable</h4>
      <div class="example-report">
        <img
          :data-src="$getAsset('/assets/services/translation/generation-poem-sample.png')"
          width="800"
          height="500"
          class="lazyload with-shadow"
        />
      </div>
      <hr />
      <h5 class="centered">Unlock your family history today.</h5>
      <mcr-buttons-row>
        <mcr-button-router-link-to-pr label="Get in touch"></mcr-button-router-link-to-pr>
      </mcr-buttons-row>
    </div>
  </div>
</template>

<script>
import McrButtonRouterLinkToPr from '@common/elements/buttons/mcrButtonRouterLinkToPr';
import mcrButtonsRow from '@common/elements/buttons/mcrButtonsRow';

export default {
  metaInfo: {
    title: "Translate Family's Poem",
    meta: [
      {
        vmid: 'description',
        name: 'description',
        content:
          "Choose a Chinese name for your kids according to your family's generation poem, with help from our expert genealogists.",
      },
    ],
  },
  components: {
    McrButtonRouterLinkToPr,
    mcrButtonsRow,
  },
};
</script>

<style lang="scss" scoped>
@import '~@/components/common/styles/services.scss';
.example-report {
  padding: 40px 0;
}
</style>
