<template>
  <div class="xmas-link">
    <div class="xmas-discount-notice">
      <span class="discount-amount">20% Off</span>
      <div class="discount-details">
        <span class="discount-coverage">All our services</span>
        <span class="discount-length">until Jan 8, 2024</span>
      </div>
      <img :src="this.$getAsset('/assets/promos/snowflake-right.png')" />
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style scoped lang="scss">
.xmas-link {
  &:hover {
    text-decoration: none;
  }
  display: block;
}
.xmas-discount-notice {
  background-color: $power-red;
  border-radius: 8px;
  color: white;
  position: relative;
  width: 100%;
  box-sizing: border-box;
  max-width: $content-max-width;
  padding: 20px;

  img {
    position: absolute;
    top: 0px;
    right: 0px;
  }

  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;

  span {
    text-shadow: 2px 2px 5px rgba(black, 0.3);
  }

  .discount-amount {
    font-size: 3em;
    margin-right: 28px;
    font-weight: bold;
  }

  .discount-details {
    display: flex;
    flex-direction: column;
    margin-right: calc(10% + 20px);
    .discount-coverage {
      font-size: 1.1em;
    }
    .discount-length {
      opacity: 0.6;
    }
  }

  @media only screen and (max-width: $breakpoint-phablet) {
    flex-direction: column;
    align-items: flex-start;
    .discount-amount {
      margin-right: 0;
    }
    img {
      top: auto;
      bottom: 9px;
      right: 2px;
      transform: scaleY(-1);
      max-width: 30%;
      @media only screen and (max-width: $breakpoint-mobile-se) {
        max-width: 20%;
        right: 18px;
        bottom: 18px;
      }
    }
  }
}
</style>
