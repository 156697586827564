<template>
  <div class="table-of-contents-page">
    <header class="text_only">
      <p class="title">Zupu Translation Services</p>
      <h1>Get your Zupu's Table of Contents</h1>
      <div class="header_actions">
        <mcr-buttons-row>
          <mcr-button-router-link-to-pr label="Enquire"></mcr-button-router-link-to-pr>
        </mcr-buttons-row>
      </div>
    </header>
    <div class="readable_content">
      <a
        rel="nofollow"
        :href="$getAsset('/assets/services/translation/MyChinaRoots-Zupu-Translation Table-of-Contents-SAMPLE.pdf')"
        target="_blank"
      >
        <image-caption
          :img-source="$getAsset('/assets/services/translation/screen_zupu.jpg')"
          caption="View Sample"
          width="800"
          height="550"
        ></image-caption>
      </a>
      <p>
        Want to know the contents of your family’s zupu? We'll summarize the key sections in English, and provide a page
        index for your reference.
      </p>

      <h4>How it Works</h4>
      <div class="figures-highlight">
        <div class="breakdown-property">
          <span class="price_label">Average Price</span>
          <span class="price">US$ 130</span>
        </div>
        <div class="breakdown-property">
          <span class="price_label">Report received</span>
          <span class="price"> >2 weeks</span>
        </div>
      </div>
      <div class="process-walkthrough">
        <div class="label">Step-by-step</div>
        <ul>
          <li>We’ll ask you to share your family’s book with us, and any ancestor names we should look out for.</li>
          <li>
            Our translators will go through the book to identify its key sections, and draw up a Table of Contents.
            We’ll highlight any chapters that are most relevant to your family.
          </li>
          <li>
            We’ll share the Table of Contents as a PDF, and answer any questions you might have about the contents.
          </li>
        </ul>
      </div>
      <hr />
      <h5 class="centered">Unlock your family history today.</h5>
      <mcr-buttons-row>
        <mcr-button-router-link-to-pr label="Get in touch"></mcr-button-router-link-to-pr>
      </mcr-buttons-row>
    </div>
  </div>
</template>

<script>
import McrButtonRouterLinkToPr from '@common/elements/buttons/mcrButtonRouterLinkToPr';
import mcrButtonsRow from '@common/elements/buttons/mcrButtonsRow';
import ImageCaption from '@common/elements/image-caption';

export default {
  metaInfo: {
    title: "Zupu's Table of Contents",
    meta: [
      {
        vmid: 'description',
        name: 'description',
        content: 'Get a summary of contents in your Chinese family tree book.',
      },
    ],
  },
  components: {
    ImageCaption,
    McrButtonRouterLinkToPr,
    mcrButtonsRow,
  },
};
</script>

<style lang="scss" scoped>
@import '~@/components/common/styles/services.scss';
</style>
